<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <div class="flex flex-col">
        <div class="w-11/12 mx-auto">
          <form @submit.prevent="submit">
            <h2 class="text-2xl mb-4 font-bold">Dados de Endereço</h2>

            <div class="flex flex-col">
              <label class="mb-2" for="cep">CEP</label>
              <input
                id="cep"
                type="text"
                v-model="cep"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                placeholder="CEP"
                v-mask="'#####-###'"
              />
            </div>
            <div class="flex flex-col">
              <label class="mb-2" for="address">Endereço</label>
              <input
                id="address"
                type="text"
                v-model="address"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                placeholder="Endereço"
              />
            </div>
            <div class="flex flex-col">
              <label class="mb-2" for="number">Número</label>
              <input
                id="number"
                type="text"
                v-model="number"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                placeholder="Número"
              />
            </div>
            <div class="flex flex-col">
              <label class="mb-2" for="complement">complemento</label>
              <input
                id="complement"
                type="text"
                v-model="complement"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                placeholder="Complemento"
              />
            </div>
            <div class="flex flex-col">
              <label class="mb-2" for="neighborhood">Bairro</label>
              <input
                id="neighborhood"
                type="text"
                v-model="neighborhood"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                placeholder="Bairro"
              />
            </div>

            <div class="flex flex-col">
              <label class="mb-2" for="state">Estado</label>
              <multiselect
                id="state"
                v-model="states.selected"
                deselect-label="Remover"
                track-by="name"
                label="name"
                select-label="Selecionar"
                selected-label="Selecionado"
                placeholder="Selecione um estado"
                :options="states.options"
                @select="fetchCities"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.name }}
                </template>
                <span slot="noOptions">Nenhum registro encontrado.</span>
              </multiselect>
            </div>

            <div class="flex flex-col">
              <label class="mb-2" for="city">Cidade</label>
              <multiselect
                id="city"
                v-model="$v.cities.selected.$model"
                deselect-label="Remover"
                track-by="name"
                label="name"
                select-label="Selecionar"
                selected-label="Selecionado"
                placeholder="Selecione uma cidade"
                :options="cities.options"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.name }}
                </template>
                <span slot="noOptions">Nenhum registro encontrado.</span>
              </multiselect>
              <div v-if="$v.cities.selected.$error">
                <div class="error" v-if="!$v.cities.selected.required">
                  Campo obrigatório.
                </div>
              </div>
            </div>

            <div class="flex justify-between">
              <button
                class="bg-gray-400 text-white px-4 py-2 rounded rounded-md border border-gray-400 focus:outline-none mt-5"
                @click.prevent="goBack"
              >
                Voltar
              </button>

              <button
                type="submit"
                class="bg-green-1000 text-white px-4 py-2 rounded rounded-md border border-gray-400 focus:outline-none mt-5"
              >
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import { mask } from 'vue-the-mask';
import { required } from 'vuelidate/lib/validators';
import axios from '@/utils/axios';
import { mapGetters } from 'vuex';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  name: 'ProfileAddress',

  title() {
    return `${process.env.VUE_APP_NAME} | Meu Endereço`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  directives: {
    mask
  },

  data() {
    return {
      isLoading: false,
      cep: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      states: {
        options: [],
        selected: null
      },
      cities: {
        options: [],
        selected: null
      }
    };
  },

  validations: {
    cities: {
      selected: {
        required
      }
    }
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user'
    })
  },

  methods: {
    async fetchAddress() {
      this.isLoading = true;
      const address = await axios.get('/api/perfil/endereco');

      if (address.data.data) {
        this.cep = address.data.data.cep;
        this.address = address.data.data.address;
        this.number = address.data.data.number;
        this.complement = address.data.data.complement;
        this.neighborhood = address.data.data.neighborhood;
        this.states.selected = address.data.data.state;

        if (this.states.selected) {
          await this.fetchCities(this.states.selected);

          this.cities.selected = address.data.data.city;
        }
      }
      this.isLoading = false;
    },

    submit() {
      if (this.user.role.name === 'Instructor') {
        this.$confirm({
          title: 'Usuário de demonstração',
          message:
            'Usuário de demonstração não tem permissão para alterar dados.',
          button: {
            yes: 'OK'
          }
        });
        return;
      }
      if (this.$v.$invalid) {
        this.$v.cities.selected.$touch();
      } else {
        axios
          .patch('/api/perfil/endereco', {
            cep: this.cep,
            address: this.address,
            number: this.number,
            complement: this.complement,
            neighborhood: this.neighborhood,
            city_id: this.cities.selected.id
          })
          .then(({ data }) => {
            this.$toast.success(data.data.message);

            this.$router.push({
              name: 'my-complete-curriculum'
            });
          })
          .catch(({ response }) => {
            Object.values(response.data.errors).forEach(error => {
              this.$toast.error(...error);
            });
          });
      }
    },

    goBack() {
      if (
        this.user.role.name === 'participant' ||
        this.user.role.name === 'Instructor'
      ) {
        this.$router.push({
          name: 'my-complete-curriculum'
        });
      } else {
        this.$router.push({
          name: 'search'
        });
      }
    },

    async fetchStates() {
      await axios.get('/api/estados').then(({ data }) => {
        data.data.forEach(state =>
          this.states.options.push({
            id: state.id,
            name: state.name
          })
        );
      });
    },

    async fetchCities(state) {
      this.cities.options = [];
      this.cities.selected = null;

      await axios.get(`/api/estados/${state.id}/cidades`).then(({ data }) => {
        data.data.forEach(city =>
          this.cities.options.push({
            id: city.id,
            name: city.name
          })
        );
      });
    }
  },

  created() {
    this.fetchStates();
    if (this.user.role.name === 'Instructor') {
      return;
    }
    this.fetchAddress();
  }
};
</script>

<style scoped></style>
